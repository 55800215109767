import { Box, styled } from '@mui/material';

export const LoaderContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: 999,
  backgroundColor: `rgba(34, 38, 49, 0.1)`,
}));
