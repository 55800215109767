import { FormLabel, List, ListItem, styled } from '@mui/material';

export const FileInput = styled('input')`
  position: absolute;
  top: 0;
  left: 0;
  height: 44px;
  width: 100%;
  z-index: 2;
  opacity: 0;
  cursor: pointer;
`;

export const StyledList = styled(List)`
  display: flex;
  flex: 1;
`;

export const StyledListItem = styled(ListItem)(({ theme }) => ({
  maxWidth: 115,
  ...theme.typography.body2,
  marginRight: theme.spacing(1),
}));

export const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  marginBottom: theme.spacing(0.5),
}));
