import { SvgIcon, useTheme } from '@mui/material';

import { icons } from '@/utils';

import type { IconProps } from './types';

export function Icon({ name, color, size = 24, ...props }: IconProps) {
  const theme = useTheme();

  return (
    <SvgIcon
      data-test-name={name}
      component={icons[name]}
      htmlColor={color ? color(theme) : theme.palette.text.primary}
      inheritViewBox
      sx={{ width: size, height: size }}
      {...props}
    />
  );
}
