import '@/config/sentry';

import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import { isProd } from '@/config';

import { App } from './App';

const root = createRoot(document.getElementById('root')!);

if (isProd) {
  root.render(
    <StrictMode>
      <App />
    </StrictMode>
  );
} else {
  root.render(<App />);
}
