import type { Components } from '@mui/material';
import { createTheme } from '@mui/material';

import { layoutConfigs } from './layout';
import { paletteConfigs } from './palette';
import { typographyConfigs } from './typography';

const theme = createTheme({
  palette: paletteConfigs,
  typography: typographyConfigs,
  ...layoutConfigs,
});

export const checkboxConfigs: Components['MuiCheckbox'] = {
  defaultProps: {
    size: 'small',
  },
  styleOverrides: {
    root: {
      height: 36,
      padding: theme.spacing(1),
      svg: {
        fill: 'none',
      },
    },
  },
};
