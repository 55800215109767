import { http } from './axiosConfig';

export const requestPersonalLoan = async (data: FormData) => {
  const response = await http.post('/loan', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return response;
};
