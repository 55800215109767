import { Button as MuiButton } from '@mui/material';

import { Icon } from '../Icon';
import type { ButtonProps } from './types';

export function Button({
  startIcon,
  endIcon,
  variant = 'primary',
  ...props
}: ButtonProps) {
  return (
    <MuiButton
      {...props}
      variant={variant === 'primary' ? 'contained' : 'outlined'}
      startIcon={
        startIcon ? (
          <Icon
            name={startIcon}
            color={(theme) =>
              variant === 'primary'
                ? theme.palette.common.white
                : theme.palette.grey[1000]
            }
          />
        ) : null
      }
      endIcon={
        endIcon ? (
          <Icon
            name={endIcon}
            color={(theme) =>
              variant === 'primary'
                ? theme.palette.common.white
                : theme.palette.grey[1000]
            }
          />
        ) : null
      }
    />
  );
}
