import * as Sentry from '@sentry/react';
import axios, { AxiosError } from 'axios';

export const http = axios.create({
  baseURL: import.meta.env.VITE_API,
});

http.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    Sentry.captureMessage('some', 'debug');
    if (error.response) {
      Sentry.setExtra('payload', error.response.data);
      Sentry.captureException(error.response.data);
    } else if (error.request) {
      Sentry.setExtra('request', error.request);
      Sentry.captureException(error.request);
    } else {
      Sentry.captureException(error.message);
    }

    return Promise.reject(error);
  }
);
