import { yupResolver } from '@hookform/resolvers/yup';
import { isAfter } from 'date-fns';
import { Resolver } from 'react-hook-form';
import * as yup from 'yup';

import { Country } from '@/constants';
import { requiredFiled } from '@/utils';

import {
  DocumentType,
  Education,
  EmploymentLength,
  Industry,
  Position,
} from './types';

const idDocumentSchema = yup.object({
  documentType: yup
    .mixed()
    .oneOf([...Object.keys(DocumentType)])
    .required(requiredFiled('Type of document')),
  number: yup.string().required(requiredFiled('Document number')),
  issueDate: yup.string().required(requiredFiled('Issue date')),
  expirationDate: yup
    .string()
    .test('laterIssueDate', (value, context) => {
      return isAfter(value, context.parent.issueDate);
    })
    .required(requiredFiled('Expiration date')),
});

const addressSchema = yup.object({
  apartment: yup.string(),
  city: yup.string().required(requiredFiled('City')),
  countryCode: yup
    .mixed()
    .oneOf([...Object.keys(Country)])
    .required(requiredFiled('Country')),
  postalCode: yup.string(),
  state: yup.string(),
  streetAddress: yup.string().required(requiredFiled('Street')),
});

const bankAccountSchema = yup.object({
  accountNumber: yup.string().required(requiredFiled('Account number')),
  bankTitle: yup.string().notRequired(),
});

export const personalLoanSchema = yup.object({
  idDocument: yup.array().of(yup.mixed<File>().required()).required(),
  borrower: yup.object({
    firstName: yup.string().required(requiredFiled('First name')),
    middleName: yup.string(),
    lastName: yup.string().required(requiredFiled('Last name')),
    birthDate: yup.string().required(requiredFiled('Date of birth')),
    email: yup.string().email().required(requiredFiled('Email')),
    phone: yup.string().required(requiredFiled('Phone')),
    idDocument: idDocumentSchema,
    address: addressSchema,
    bankAccount: bankAccountSchema,
    citizenship: yup.string().notRequired(),
    education: yup
      .mixed()
      .oneOf([...Object.keys(Education)])
      .required(requiredFiled('Education level')),
    industry: yup
      .mixed()
      .oneOf([...Object.keys(Industry)])
      .required(requiredFiled('Industry')),
    lengthOfEmployment: yup
      .mixed()
      .oneOf([...Object.keys(EmploymentLength), '']),
    maritalStatus: yup.string().required(requiredFiled('Marital status')),
    monthlyIncome: yup.string().required(requiredFiled('Monthly income')),
    position: yup
      .mixed()
      .oneOf([...Object.keys(Position)])
      .required(requiredFiled('Job position')),
    property: yup.boolean(),
    vehicle: yup.boolean(),
    taxNumber: yup.string(),
  }),
  amountRequested: yup.string().required(requiredFiled('Amount of loan')),
  campaignCode: yup.string().required(requiredFiled('Code of campaign')),
  paymentFrequency: yup.string().notRequired(),
  scheduledDayOfPayment: yup.string().required(requiredFiled('Scheduled day')),
  termRequested: yup.string().required(requiredFiled('Term of loan')),
});

export type PersonalLoanSchemaType = yup.InferType<typeof personalLoanSchema>;

export const personalLoanResolver: Resolver<PersonalLoanSchemaType> =
  yupResolver(personalLoanSchema);
