import type { Components } from '@mui/material';
import { createTheme } from '@mui/material';

import { elevationLevel } from './constants/paper';
import { layoutConfigs } from './layout';
import { paletteConfigs } from './palette';
import { typographyConfigs } from './typography';

const theme = createTheme({
  palette: paletteConfigs,
  typography: typographyConfigs,
  ...layoutConfigs,
});

export const selectConfigs: Components['MuiSelect'] = {
  defaultProps: {
    size: 'small',
    variant: 'outlined',
    fullWidth: true,
    MenuProps: {
      autoFocus: false,
      transitionDuration: 0,
      slotProps: {
        paper: {
          elevation: elevationLevel.m,
          sx: (theme) => ({
            border: `1px solid ${theme.palette.grey[200]}`,
            padding: `${theme.spacing(2)} ${theme.spacing(1)}`,
            paddingTop: 0,
            maxHeight: 200,

            '.MuiList-root': {
              minWidth: 125,
              padding: 0,
            },

            [theme.breakpoints.up('lg')]: {
              maxHeight: 280,
              maxWidth: 360,
            },
          }),
        },
      },
    },
  },
  styleOverrides: {
    root: {
      minWidth: 0,
      height: 44,
      paddingRight: theme.spacing(0.5),
      backgroundColor: theme.palette.grey[100],

      '.MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input': {
        paddingRight: 0,
      },

      '.MuiOutlinedInput-notchedOutline': {
        border: `1px solid ${theme.palette.grey[600]}`,
        transition: theme.transitions.create(['border-color', 'color']),
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.grey[1000],
        borderWidth: 1,
      },
      '&.Mui-error .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.error.main,
        borderWidth: 1,
      },
    },
  },
};
