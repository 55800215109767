import type { Components } from '@mui/material';
import { createTheme } from '@mui/material';

import { layoutConfigs } from './layout';
import { paletteConfigs } from './palette';
import { typographyConfigs } from './typography';

const theme = createTheme({
  palette: paletteConfigs,
  typography: typographyConfigs,
  ...layoutConfigs,
});

export const outlinedInputConfigs: Components['MuiOutlinedInput'] = {
  styleOverrides: {
    root: {
      height: 44,
      'input:is(:-webkit-autofill, :autofill)': {
        WebkitBoxShadow: `0 0 0 30px ${theme.palette.grey[100]} inset`,
      },
      '&.Mui-disabled': {
        backgroundColor: theme.palette.grey[200],
        input: {
          WebkitTextFillColor: theme.palette.grey[1000],
        },
      },
      '.MuiOutlinedInput-input.Mui-disabled': {
        WebkitTextFillColor: theme.palette.grey[1000],
      },
      fieldset: {
        top: 0,
        borderWidth: 1,
        borderColor: theme.palette.grey[600],
        ':hover': {
          borderColor: theme.palette.grey[1000],
        },
        legend: {
          display: 'none',
        },
      },
    },
  },
};
