import { CircularProgress } from '@mui/material';

import { LoaderContainer } from './Loader.styled';
import { LoaderProps } from './types';

export function Loader({ size = 'md' }: LoaderProps) {
  const getLoaderSize = () => {
    switch (size) {
      case 'sm':
        return 16;
      case 'lg':
        return 48;
      case 'md':
        return 32;
      default:
        return;
    }
  };

  return (
    <LoaderContainer>
      <CircularProgress size={getLoaderSize()} />
    </LoaderContainer>
  );
}
