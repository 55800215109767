import type { TextFieldProps } from '@mui/material';
import { InputAdornment } from '@mui/material';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers';
import { format, parseISO } from 'date-fns';
import { useCallback } from 'react';
import type { FieldValues, RefCallBack } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import { useBoolean } from 'usehooks-ts';

import { Icon } from '@/components/Icon';
import { DATE_VALUE_FORMAT } from '@/constants';
import { elevationLevel } from '@/styles/constants/paper';
import { getFieldValue } from '@/utils';

import { CalendarButton } from './DatePicker.styled';
import type { DatePickerProps } from './types';

export function DatePicker<FormPayload extends FieldValues>({
  control,
  rules,
  name,
  error,
  disabled,
  placeholder = 'Select date',
  ...props
}: DatePickerProps<FormPayload>) {
  const { value: open, setTrue: show, setFalse: hide } = useBoolean();

  const render = useCallback(
    ({
      onChange,
      onBlur,
      defaultValue,
      ref,
    }: {
      value: string;
      onChange: (value: string) => void;
      onBlur: TextFieldProps['onBlur'];
      defaultValue?: string;
      ref?: RefCallBack;
    }) => {
      const handleOnChange: DatePickerProps<Date>['onChange'] = (
        value: Date | null
      ) => {
        if (value) {
          onChange(format(value, 'yyyy-MM-dd'));
        }
      };

      return (
        <MuiDatePicker
          ref={ref}
          inputRef={ref}
          onChange={handleOnChange}
          defaultValue={defaultValue ? parseISO(defaultValue) : undefined}
          open={open}
          onClose={hide}
          reduceAnimations
          format={DATE_VALUE_FORMAT}
          disabled={disabled}
          slotProps={{
            desktopPaper: {
              elevation: elevationLevel.l,
            },
            textField: {
              onBlur,
              onClick: show,
              error: !open && !!error,
              helperText: error,
              placeholder,
              InputProps: {
                endAdornment: (
                  <InputAdornment position="end">
                    <CalendarButton disabled={disabled} type="button">
                      <Icon
                        name="calendar"
                        color={(theme) => theme.palette.grey[700]}
                      />
                    </CalendarButton>
                  </InputAdornment>
                ),
              },
            },
          }}
          {...props}
        />
      );
    },
    [error, hide, open, placeholder, props, show]
  );

  return (
    <Controller
      rules={rules}
      control={control}
      name={name}
      render={({
        field: { ref, onChange, value, onBlur },
        formState: { defaultValues },
      }) =>
        render({
          value,
          onChange,
          onBlur,
          defaultValue: getFieldValue(name, defaultValues),
          ref,
        })
      }
    />
  );
}
