import type { Components } from '@mui/material';
import { createTheme } from '@mui/material';

import { layoutConfigs } from './layout';
import { paletteConfigs } from './palette';
import { typographyConfigs } from './typography';

const theme = createTheme({
  palette: paletteConfigs,
  typography: typographyConfigs,
  ...layoutConfigs,
});

export const textFieldConfigs: Components['MuiTextField'] = {
  defaultProps: {
    variant: 'outlined',
    fullWidth: true,
  },
  styleOverrides: {
    root: {
      padding: 0,
      marginTop: 0,
      color: theme.palette.grey[1000],
      ...theme.typography.body2,
      ...theme.shape,
      '.MuiInputBase-root': {
        '&:not(.MuiInputBase-multiline)': {
          height: 44,
        },
      },
      '.MuiOutlinedInput-root': {
        backgroundColor: theme.palette.grey[100],
        ...theme.typography.body2,
        '&.Mui-focused fieldset': {
          borderWidth: 1,
          borderColor: theme.palette.grey[700],
        },
        '&.Mui-error fieldset': {
          borderColor: theme.palette.error.main,
        },
        '.MuiInputBase-input': {
          padding: theme.spacing(1, 2),
          '::placeholder': {
            color: theme.palette.grey[700],
            opacity: 1,
          },
        },
        '.MuiInputBase-inputMultiline': {
          padding: 0,
        },
        '&.MuiInputBase-adornedStart': {
          paddingLeft: theme.spacing(2),
          '& .MuiInputBase-input': {
            paddingLeft: theme.spacing(0.5),
          },
        },
        '&.MuiInputBase-adornedEnd': {
          paddingRight: theme.spacing(2),
          '& .MuiInputBase-input': {
            paddingRight: theme.spacing(0.5),
          },
        },
      },
      '.MuiFormHelperText-root': {
        marginLeft: 0,
      },
    },
  },
};
