import type { Components } from '@mui/material';
import { createTheme } from '@mui/material';

import { layoutConfigs } from './layout';
import { paletteConfigs } from './palette';
import { shadowsConfigs } from './shadows';

const theme = createTheme({
  palette: paletteConfigs,
  shadows: shadowsConfigs,
  ...layoutConfigs,
});

export const paperConfigs: Components['MuiPaper'] = {
  styleOverrides: {
    root: {
      padding: theme.spacing(3),
      ...theme.shape,
      ...theme.shadows,
    },
  },
};
