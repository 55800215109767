import type { Components } from '@mui/material';
import { createTheme } from '@mui/material';

import { layoutConfigs } from './layout';
import { paletteConfigs } from './palette';
import { typographyConfigs } from './typography';

const theme = createTheme({
  palette: paletteConfigs,
  typography: typographyConfigs,
  ...layoutConfigs,
});

export const inputLabelConfigs: Components['MuiInputLabel'] = {
  defaultProps: {
    shrink: true,
  },
  styleOverrides: {
    root: {
      transform: 'none',
      position: 'unset',
      marginBottom: theme.spacing(0.5),
    },
  },
};
