import { Box, Typography } from '@mui/material';
import { addDays } from 'date-fns';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { requestPersonalLoan } from '@/api';
import {
  Button,
  Checkbox,
  DatePicker,
  Loader,
  SelectField,
  TextField,
  UploadFile,
} from '@/components';
import { countryOptions } from '@/constants';
import { useSnackbar } from '@/context/SnackbarContext';
import { getSelectOptions } from '@/utils';

import {
  personalLoanResolver,
  PersonalLoanSchemaType,
} from './PersonalLoan.schema';
import {
  FormRow,
  FormRowColumn,
  FormSectionTitle,
  StyledContainer,
  StyledForm,
  StyledPaper,
} from './PersonalLoan.styled';
import {
  DocumentType,
  Education,
  EmploymentLength,
  Industry,
  MaritalStatus,
  PaymentFrequency,
  Position,
} from './types';
import { formatData } from './utils';

export function PersonalLoan() {
  const [loading, setLoading] = useState(false);

  const { showSnackbar } = useSnackbar();
  const {
    control,
    getValues,
    watch,
    formState: { errors },
    handleSubmit,
  } = useForm<PersonalLoanSchemaType>({
    defaultValues: {
      borrower: {
        property: false,
        vehicle: false,
      },
    },
    resolver: personalLoanResolver,
  });

  const onSubmit: SubmitHandler<PersonalLoanSchemaType> = async (data) => {
    try {
      setLoading(true);

      await requestPersonalLoan(formatData(data));

      showSnackbar({
        message: 'Your loan request has been sent successfully',
        variant: 'success',
      });
    } catch {
      showSnackbar({
        message: 'Failed to request a loan. Try again',
        variant: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const watchIssueDate = watch('borrower.idDocument.issueDate');

  return (
    <StyledContainer>
      <StyledPaper>
        {loading && <Loader />}
        <Typography variant="h3" alignSelf="center" mb={2}>
          Fill this form to request a loan
        </Typography>
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
          <FormSectionTitle>Personal data</FormSectionTitle>
          <FormRow>
            <TextField
              name="borrower.firstName"
              control={control}
              label="First Name*"
              placeholder="Enter your first name"
              error={errors.borrower?.firstName?.message}
            />
            <TextField
              name="borrower.middleName"
              control={control}
              label="Middle Name"
              placeholder="Enter your middle name"
              error={errors.borrower?.middleName?.message}
            />
          </FormRow>
          <FormRow>
            <TextField
              name="borrower.lastName"
              control={control}
              label="Last Name*"
              placeholder="Enter your last name"
              error={errors.borrower?.lastName?.message}
            />
            <DatePicker
              label="Date of birth*"
              control={control}
              name="borrower.birthDate"
              placeholder="Select your birth date"
              error={errors.borrower?.birthDate?.message}
            />
          </FormRow>
          <FormRow>
            <TextField
              name="borrower.email"
              control={control}
              label="Email*"
              type="email"
              placeholder="Enter your email"
              error={errors.borrower?.email?.message}
            />
            <TextField
              name="borrower.phone"
              control={control}
              label="Phone*"
              placeholder="Enter your phone"
              error={errors.borrower?.phone?.message}
            />
          </FormRow>
          <FormRowColumn>
            <UploadFile
              name="idDocument"
              control={control}
              label="Document that approve your identity*"
              error={!!errors.idDocument}
            />
          </FormRowColumn>
          <FormRow>
            <SelectField
              name="borrower.idDocument.documentType"
              control={control}
              label="Type of document*"
              options={getSelectOptions(DocumentType)}
              placeholder="Select document type"
              error={errors.borrower?.idDocument?.documentType?.message}
            />
            <TextField
              name="borrower.idDocument.number"
              control={control}
              label="Document number*"
              placeholder="Enter your document number"
              error={errors.borrower?.idDocument?.number?.message}
            />
          </FormRow>
          <FormRow>
            <DatePicker
              name="borrower.idDocument.issueDate"
              control={control}
              label="Issue date*"
              placeholder="Select issue date"
              disableFuture
              error={errors.borrower?.idDocument?.issueDate?.message}
            />
            <DatePicker
              name="borrower.idDocument.expirationDate"
              control={control}
              label="Expiration date*"
              placeholder="Select expiration date"
              minDate={addDays(getValues('borrower.idDocument.issueDate'), 1)}
              disablePast
              disabled={!watchIssueDate}
              error={errors.borrower?.idDocument?.expirationDate?.message}
            />
          </FormRow>
          <FormRowColumn>
            <SelectField
              label="Marital status*"
              name="borrower.maritalStatus"
              control={control}
              options={getSelectOptions(MaritalStatus)}
              placeholder="Select marital status"
              error={errors.borrower?.maritalStatus?.message}
            />
          </FormRowColumn>
          <FormRowColumn>
            <SelectField
              name="borrower.education"
              control={control}
              options={getSelectOptions(Education)}
              label="Education level*"
              placeholder="Select education level"
              error={errors.borrower?.education?.message}
            />
          </FormRowColumn>
          <FormRowColumn>
            <TextField
              name="borrower.citizenship"
              control={control}
              label="Citizenship"
              placeholder="Enter your citizenship"
              error={errors.borrower?.citizenship?.message}
            />
          </FormRowColumn>
          <FormSectionTitle>Address</FormSectionTitle>
          <FormRow>
            <TextField
              name="borrower.address.city"
              control={control}
              label="City*"
              placeholder="Enter your city"
              error={errors.borrower?.address?.city?.message}
            />
            <SelectField
              name="borrower.address.countryCode"
              control={control}
              label="Country*"
              placeholder="Enter your county"
              options={countryOptions}
              error={errors.borrower?.address?.countryCode?.message}
            />
          </FormRow>
          <FormRow>
            <TextField
              name="borrower.address.state"
              control={control}
              label="State"
              placeholder="Enter your state"
              error={errors.borrower?.address?.state?.message}
            />
            <TextField
              name="borrower.address.streetAddress"
              control={control}
              label="Street*"
              placeholder="Enter your street"
              error={errors.borrower?.address?.streetAddress?.message}
            />
          </FormRow>
          <FormRow>
            <TextField
              name="borrower.address.apartment"
              control={control}
              label="Apartment"
              placeholder="Enter your apartment"
              error={errors.borrower?.address?.apartment?.message}
            />
            <TextField
              name="borrower.address.postalCode"
              control={control}
              label="Postal code"
              placeholder="Enter your postal code"
              error={errors.borrower?.address?.postalCode?.message}
            />
          </FormRow>
          <FormSectionTitle>Economical info</FormSectionTitle>
          <FormRow>
            <SelectField
              name="borrower.industry"
              control={control}
              label="Industry*"
              options={getSelectOptions(Industry)}
              placeholder="Select your industry"
              error={errors.borrower?.industry?.message}
            />
            <SelectField
              name="borrower.position"
              control={control}
              label="Job position*"
              options={getSelectOptions(Position)}
              placeholder="Select your job position"
              error={errors.borrower?.position?.message}
            />
          </FormRow>
          <FormRow>
            <TextField
              name="borrower.taxNumber"
              control={control}
              label="Tax number"
              placeholder="Enter your tax number"
              error={errors.borrower?.taxNumber?.message}
            />
            <TextField
              name="borrower.monthlyIncome"
              control={control}
              label="Monthly income*"
              placeholder="Enter your monthly income"
              error={errors.borrower?.monthlyIncome?.message}
            />
          </FormRow>
          <FormRow>
            <TextField
              name="borrower.bankAccount.bankTitle"
              control={control}
              label="Bank name"
              placeholder="Enter your bank name"
              error={errors.borrower?.bankAccount?.bankTitle?.message}
            />
            <TextField
              name="borrower.bankAccount.accountNumber"
              control={control}
              label="Account number*"
              placeholder="Enter your account number"
              error={errors.borrower?.bankAccount?.accountNumber?.message}
            />
          </FormRow>
          <FormRowColumn>
            <SelectField
              name="borrower.lengthOfEmployment"
              control={control}
              label="Working experience"
              options={getSelectOptions(EmploymentLength)}
              placeholder="Select your working experience"
              error={errors.borrower?.lengthOfEmployment?.message}
            />
          </FormRowColumn>
          <FormRowColumn>
            <Checkbox
              label="Do you have a vehicle*"
              name="borrower.vehicle"
              control={control}
            />
            <Checkbox
              label="Do you have some property*"
              name="borrower.property"
              control={control}
            />
          </FormRowColumn>
          <FormSectionTitle>Loan data</FormSectionTitle>
          <FormRow>
            <TextField
              name="amountRequested"
              control={control}
              label="Amount of loan*"
              placeholder="Enter desire amount"
              type="number"
              error={errors.amountRequested?.message}
            />
            <TextField
              name="termRequested"
              control={control}
              label="Term of loan*"
              placeholder="Enter term in months"
              type="number"
              error={errors.termRequested?.message}
            />
          </FormRow>
          <FormRow>
            <TextField
              name="campaignCode"
              control={control}
              label="Code of campaign*"
              placeholder="Enter code"
              error={errors.campaignCode?.message}
            />
            <SelectField
              name="paymentFrequency"
              control={control}
              label="Payment frequency"
              options={getSelectOptions(PaymentFrequency)}
              placeholder="Select payment frequency"
              error={errors.paymentFrequency?.message}
            />
          </FormRow>
          <TextField
            name="scheduledDayOfPayment"
            control={control}
            label="Scheduled day*"
            error={errors.scheduledDayOfPayment?.message}
          />
          <Box mt={4} alignSelf="center" width={200}>
            <Button type="submit" fullWidth disabled={loading}>
              Request loan
            </Button>
          </Box>
        </StyledForm>
      </StyledPaper>
    </StyledContainer>
  );
}
