import { removeEmptyFromObject } from '@/utils';

import { PersonalLoanSchemaType } from '../PersonalLoan.schema';

export const formatData = (data: PersonalLoanSchemaType) => {
  const filedData = removeEmptyFromObject(data);
  const { idDocument, ...rest } = filedData;

  const formData = new FormData();

  for (const file of idDocument) {
    formData.append('idDocument', file);
  }

  formData.append(
    'loanApplicationRequest',
    JSON.stringify({
      // TODO: change callback url to real one
      callbackUrl: 'https://example.callback.url',
      request: {
        ...rest,
        borrower: {
          ...rest.borrower,
          monthlyIncome: Number(rest.borrower.monthlyIncome),
          idDocument: {
            type: rest.borrower.idDocument.documentType,
            issueDate: rest.borrower.idDocument.issueDate,
            expirationDate: rest.borrower.idDocument.expirationDate,
            number: rest.borrower.idDocument.number,
          },
        },
        amountRequested: Number(rest.amountRequested),
        scheduledDayOfPayment: Number(rest.scheduledDayOfPayment),
        termRequested: Number(rest.termRequested),
        requestedAt: new Date().toISOString(),
      },
    })
  );

  return formData;
};
