import {
  Box,
  Container,
  Paper,
  styled,
  Typography,
  TypographyProps,
} from '@mui/material';

export const FormRow = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  marginBottom: theme.spacing(2),

  '& > :first-of-type': {
    marginRight: theme.spacing(3),
  },
}));

export const FormSectionTitle = styled((props: TypographyProps) => (
  <Typography variant="h5" {...props} />
))(({ theme }) => ({
  margin: theme.spacing(2, 0),
}));

export const FormRowColumn = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  marginBottom: theme.spacing(2),
}));

export const StyledContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  paddingTop: theme.spacing(4),
}));

export const StyledPaper = styled(Paper)(() => ({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: 768,
  width: '100%',
  position: 'relative',
  overflow: 'hidden',
}));

export const StyledForm = styled('form')(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
}));
