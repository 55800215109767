import type { Components } from '@mui/material';
import { createTheme } from '@mui/material';

import { paletteConfigs } from './palette';
import { typographyConfigs } from './typography';

const theme = createTheme({
  palette: paletteConfigs,
  typography: typographyConfigs,
});

export const formLabelConfigs: Components['MuiFormLabel'] = {
  styleOverrides: {
    root: {
      color: theme.palette.grey[1000],
      ...theme.typography.body2Medium,
      '&.MuiInputLabel-shrink': {
        color: theme.palette.text.primary,
      },
      '&.Mui-error': {
        color: theme.palette.error.main,
      },
    },
  },
};
