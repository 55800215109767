import { IconButton, MenuItem, styled } from '@mui/material';

export const FieldArrowButton = styled(IconButton)(({ theme }) => ({
  padding: theme.spacing(1),
  ':hover': {
    backgroundColor: 'transparent',
  },

  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(0.5),
  },
}));

export const AutocompleteOption = styled(MenuItem)(({ theme }) => ({
  borderRadius: 8,
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  paddingTop: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),
  minHeight: 32,
  fontSize: theme.typography.body2.fontSize,
  wordWrap: 'break-word',
  whiteSpace: 'wrap',

  ':hover': {
    backgroundColor: theme.palette.grey[100],
  },
}));
