import type { Components } from '@mui/material';
import { createTheme } from '@mui/material';

import { paletteConfigs } from './palette';
import { typographyConfigs } from './typography';

const theme = createTheme({
  palette: paletteConfigs,
  typography: typographyConfigs,
});

export const avatarConfigs: Components['MuiAvatar'] = {
  styleOverrides: {
    root: {
      backgroundColor: theme.palette.primary400.main,
      fontSize: theme.typography.body2Bold.fontSize,
      fontWeight: theme.typography.body2Bold.fontWeight,
      textTransform: 'uppercase',
    },
  },
};
