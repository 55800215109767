import { AlertProps } from '@mui/material';
import { createContext, useState } from 'react';

import { Snackbar } from '@/components';

import {
  ShowSnackbarOptions,
  SnackbarContextType,
  SnackbarProviderProps,
} from './types';

export const SnackbarContext = createContext<SnackbarContextType | undefined>(
  undefined
);

export function SnackbarProvider({ children }: SnackbarProviderProps) {
  const [open, setOpen] = useState(false);
  const [autoHideDuration, setAutoHideDuration] = useState(3000);
  const [message, setMessage] = useState('');
  const [variant, setVariant] = useState<AlertProps['severity']>('info');

  const showSnackbar = ({
    message,
    variant = 'info',
    autoHideDuration,
  }: ShowSnackbarOptions) => {
    setMessage(message);
    setVariant((prevVariant) =>
      prevVariant !== variant ? variant : prevVariant
    );

    if (autoHideDuration) {
      setAutoHideDuration(autoHideDuration);
    }

    setOpen(true);
  };

  const hideSnackbar = () => {
    setOpen(false);
  };

  return (
    <SnackbarContext.Provider value={{ showSnackbar, hideSnackbar }}>
      <Snackbar
        open={open}
        message={message}
        variant={variant}
        autoHideDuration={autoHideDuration}
        onClose={hideSnackbar}
      />
      {children}
    </SnackbarContext.Provider>
  );
}
