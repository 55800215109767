import { Checkbox as MuiCheckbox, FormControlLabel } from '@mui/material';
import type { FieldValues } from 'react-hook-form';
import { Controller } from 'react-hook-form';

import { Icon } from '@/components/Icon';

import type { CheckboxProps } from './types';

export function Checkbox<FormPayload extends FieldValues>({
  control,
  name,
  rules,
  ...props
}: CheckboxProps<FormPayload>) {
  return (
    <Controller
      rules={rules}
      control={control}
      name={name}
      render={({
        field: { onChange, onBlur, value },
        formState: { defaultValues },
      }) => (
        <FormControlLabel
          label={props.label}
          control={
            <MuiCheckbox
              checked={value || false}
              defaultChecked={defaultValues?.[name]}
              icon={<Icon name="checkbox" size={20} />}
              checkedIcon={<Icon name="checkbox-checked" size={20} />}
              onChange={onChange}
              onBlur={onBlur}
              {...props}
            />
          }
        />
      )}
    />
  );
}
