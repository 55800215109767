import type { Components } from '@mui/material';
import { createTheme } from '@mui/material';

import { elevationLevel } from './constants/paper';
import { layoutConfigs } from './layout';

const theme = createTheme({
  ...layoutConfigs,
});

export const autocompleteConfigs: Components['MuiAutocomplete'] = {
  defaultProps: {
    size: 'small',
    fullWidth: true,
    slotProps: {
      paper: {
        elevation: elevationLevel.m,
        sx: (theme) => ({
          border: `1px solid ${theme.palette.grey[200]}`,
          padding: `${theme.spacing(2)} ${theme.spacing(1)}`,
        }),
      },
    },
  },
  styleOverrides: {
    root: {
      '&.MuiAutocomplete-hasPopupIcon, &.MuiAutocomplete-hasClearIcon, &.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon':
        {
          '.MuiFormControl-root': {
            '.MuiInputBase-root': {
              paddingRight: theme.spacing(2),
            },
          },
        },
    },
  },
};
