import { CssBaseline, ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';

import { theme } from '@/styles/theme';

import { SnackbarProvider } from './context/SnackbarContext';
import { PersonalLoan } from './pages';

export function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <SnackbarProvider>
          <PersonalLoan />
        </SnackbarProvider>
      </ThemeProvider>
    </LocalizationProvider>
  );
}
