import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  styled,
  Typography,
} from '@mui/material';

export const StyledFormControl = styled(FormControl)(({ theme }) => ({
  width: '100%',
  '.MuiFormHelperText-root': {
    fontSize: theme.typography.captionBold.fontSize,
    marginLeft: 0,
    marginRight: 0,

    '&.Mui-error': {
      fontWeight: theme.typography.captionBold.fontWeight,
    },
  },
}));

export const DropdownOption = styled(MenuItem)(({ theme }) => ({
  borderRadius: 8,
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  paddingTop: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  minHeight: 32,
  fontSize: theme.typography.body2.fontSize,

  ':hover': {
    backgroundColor: theme.palette.grey[100],
  },

  '.MuiListItemIcon-root': {
    minWidth: 0,

    ':first-of-type': {
      marginRight: theme.spacing(1),
    },
  },

  '.MuiListItemText-root': {
    flex: 1,
    minWidth: 0,
    wordWrap: 'break-word',
    whiteSpace: 'wrap',
  },

  '&.Mui-selected': {
    backgroundColor: 'transparent',
    pointerEvents: 'none',

    ':hover, :focus': {
      backgroundColor: 'transparent',
    },
  },

  ':not(:first-of-type)': {
    marginTop: theme.spacing(1),
  },
}));

export const SelectedOption = styled(DropdownOption)(({ theme }) => ({
  backgroundColor: 'unset',
  paddingLeft: 0,
  paddingRight: 0,

  '.MuiListItemIcon-root': {
    marginRight: theme.spacing(1),
  },

  ':hover': {
    backgroundColor: 'unset',
  },
}));

export const SelectGroup = styled(Box)(({ theme }) => ({
  ':not(:first-of-type)': {
    paddingTop: theme.spacing(3),
  },
}));

export const SelectGroupName = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),

  [theme.breakpoints.up('lg')]: {
    marginBottom: theme.spacing(1.5),
    marginTop: theme.spacing(3),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    ':nth-of-type(2)': {
      marginTop: theme.spacing(1),
    },
  },
}));
SelectGroupName.defaultProps = {
  variant: 'body2Medium',
};

export const Label = styled(InputLabel)(() => ({
  marginTop: -4,
  '&.MuiInputLabel-shrink': {
    marginTop: 0,
    marginLeft: 0,
  },
}));
