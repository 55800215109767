export const headerHeight = {
  xs: 64,
  md: 64,
  lg: 72,
};

export const breakpoints = {
  xs: 0,
  sm: 600,
  md: 1024,
  lg: 1280,
  xl: 1440,
};

export const spacing = 8;
