import type { Color } from '../types';

export const blue: Color = {
  100: '#F4FEFF',
  200: '#E9FDFF',
  300: '#AAF2FD',
  400: '#0099C7',
  500: '#007DA8',
  600: '#05648A',
  700: '#044F6C',
  800: '#06374C',
  900: '#042634',
  1000: '#000B10',
};
export const pink: Color = {
  100: '#FDF7FC',
  200: '#FBEEF8',
  300: '#F8D8F1',
  400: '#E265BA',
  500: '#CA3393',
  600: '#A32470',
  700: '#841F5A',
  800: '#681D4A',
  900: '#450C2E',
  1000: '#30081F',
};
export const purple: Color = {
  100: '#FDF6FD',
  200: '#F8E6FA',
  300: '#F3D1F5',
  400: '#CF5ECB',
  500: '#93358D',
  600: '#732B6D',
  700: '#61235D',
  800: '#3F183C',
  900: '#2F142C',
  1000: '#040104',
};
export const violet: Color = {
  100: '#F9F7FC',
  200: '#F2EFF8',
  300: '#E6DEF0',
  400: '#A688C8',
  500: '#715091',
  600: '#684B86',
  700: '#4C3663',
  800: '#392848',
  900: '#2A1E33',
  1000: '#1F132A',
};
export const grey: Color = {
  100: '#F8F8F8',
  200: '#EFEFEF',
  300: '#DCDCDC',
  400: '#C7C7C7',
  500: '#9C9C9C',
  600: '#858585',
  700: '#6B6B6B',
  800: '#4D4D4D',
  900: '#383838',
  1000: '#242424',
};

export const red: Color = {
  100: '#FEF2F2',
  200: '#FFD5D4',
  300: '#F37876',
  400: '#E94C4A',
  500: '#D63230',
  600: '#C52826',
  700: '#A32624',
  800: '#8D2725',
  900: '#751615',
  1000: '#52100F',
};
export const orange: Color = {
  100: '#FFF8ED',
  200: '#FFF0D5',
  300: '#FCC475',
  400: '#E87407',
  500: '#C1570B',
  600: '#9A4509',
  700: '#722F08',
  800: '#52210A',
  900: '#351708',
  1000: '#250D04',
};
export const yellow: Color = {
  100: '#FEFCDD',
  200: '#FFFBB8',
  300: '#FFF280',
  400: '#FFE53D',
  500: '#DBAD06',
  600: '#BB8302',
  700: '#945D05',
  800: '#79480C',
  900: '#663B0F',
  1000: '#4C2606',
};
export const green: Color = {
  100: '#F0FFF5',
  200: '#C3FEDC',
  300: '#64FCA6',
  400: '#03AA49',
  500: '#03863C',
  600: '#027331',
  700: '#025526',
  800: '#05391B',
  900: '#042513',
  1000: '#011E0E',
};

export const common: Color = {
  white: '#FFFFFF',
  black: '#000000',
};
