import type { Components } from '@mui/material';
import { createTheme } from '@mui/material';

import { layoutConfigs } from './layout';

const theme = createTheme({
  ...layoutConfigs,
});

export const listItemConfigs: Components['MuiListItem'] = {
  styleOverrides: {
    root: {
      padding: 0,

      '> .MuiListItemIcon-root': {
        minWidth: 0,

        ':first-of-type': {
          marginRight: theme.spacing(2),
        },

        ':last-of-type': {
          marginLeft: theme.spacing(2),
        },
      },

      '.MuiListItemText-root': {
        margin: 0,
      },
    },
  },
};
