import { createTheme } from '@mui/material';

import { autocompleteConfigs } from './autocomplete';
import { avatarConfigs } from './avatar';
import { buttonConfigs } from './button';
import { buttonBaseConfigs } from './buttonBase';
import { checkboxConfigs } from './checkbox';
import { cssBaseLineConfigs } from './cssBaseLine';
import { formControlLabelConfigs } from './formControlLabel';
import { formLabelConfigs } from './formLabel';
import { helperTextConfigs } from './helperText';
import { inputLabelConfigs } from './inputLabel';
import { layoutConfigs } from './layout';
import { listItemConfigs } from './listItem';
import { outlinedInputConfigs } from './outlinedInput';
import { paletteConfigs } from './palette';
import { paperConfigs } from './paper';
import { selectConfigs } from './select';
import { shadowsConfigs } from './shadows';
import { textFieldConfigs } from './textField';
import { typographyConfigs } from './typography';

const theme = createTheme({
  palette: paletteConfigs,
  typography: typographyConfigs,
  shadows: shadowsConfigs,
  components: {
    MuiCssBaseline: cssBaseLineConfigs,
    MuiButton: buttonConfigs,
    MuiFormLabel: formLabelConfigs,
    MuiTextField: textFieldConfigs,
    MuiPaper: paperConfigs,
    MuiSelect: selectConfigs,
    MuiListItem: listItemConfigs,
    MuiAvatar: avatarConfigs,
    MuiAutocomplete: autocompleteConfigs,
    MuiButtonBase: buttonBaseConfigs,
    MuiInputLabel: inputLabelConfigs,
    MuiOutlinedInput: outlinedInputConfigs,
    MuiCheckbox: checkboxConfigs,
    MuiFormControlLabel: formControlLabelConfigs,
    MuiFormHelperText: helperTextConfigs,
  },
  ...layoutConfigs,
});

export { theme };
