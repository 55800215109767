import type { TextFieldProps as MuiTextFieldProps } from '@mui/material';
import {
  IconButton,
  InputAdornment,
  TextField as MuiTextField,
} from '@mui/material';
import { useCallback, useState } from 'react';
import type { FieldValues } from 'react-hook-form';
import { Controller } from 'react-hook-form';

import { getFieldValue } from '@/utils';

import { Icon } from '../../Icon';
import type { TextFieldProps } from './types';

export function TextField<FormPayload extends FieldValues>({
  control,
  rules,
  name,
  template,
  error,
  type = 'text',
  value,
  placeholder,
  onChange,
  onBlur,
  ...props
}: TextFieldProps<FormPayload>) {
  const [passwordType, setPasswordType] =
    useState<MuiTextFieldProps['type']>('password');

  const togglePasswordVisibility = () =>
    setPasswordType((prev) => (prev === 'password' ? 'text' : 'password'));

  const render = useCallback(
    ({
      value,
      onChange,
      onBlur,
      defaultValue,
      isDirty,
    }: {
      value: MuiTextFieldProps['value'];
      onChange: MuiTextFieldProps['onChange'];
      onBlur: MuiTextFieldProps['onBlur'];
      defaultValue?: MuiTextFieldProps['defaultValue'];
      isDirty?: boolean;
    }) => {
      const isPasswordInput = type === 'password';

      const textFieldProps: MuiTextFieldProps = {
        name,
        type: isPasswordInput ? passwordType : type,
        value:
          (value && typeof value === 'string') || isDirty
            ? value || ''
            : defaultValue || '',
        onChange,
        onBlur: onBlur,
        error: !!error,
        helperText: error,
        InputProps: {
          startAdornment:
            template === 'search' ? (
              <InputAdornment position="start">
                <Icon
                  name="search"
                  size={20}
                  color={(theme) => theme.palette.grey[700]}
                />
              </InputAdornment>
            ) : undefined,
          endAdornment: isPasswordInput ? (
            <InputAdornment position="end">
              <IconButton
                title={
                  passwordType === 'password'
                    ? 'Show password'
                    : 'Hide password'
                }
                onClick={togglePasswordVisibility}
                edge="end"
                size="small"
              >
                <Icon
                  name={passwordType === 'password' ? 'eye' : 'eye-closed'}
                  color={(theme) => theme.palette.grey[700]}
                />
              </IconButton>
            </InputAdornment>
          ) : null,
        },
        placeholder:
          placeholder || (template === 'search' ? 'Search' : undefined),
        ...props,
      };

      return <MuiTextField {...textFieldProps} />;
    },
    [error, name, passwordType, placeholder, props, template, type]
  );

  if (control && name) {
    return (
      <Controller
        rules={rules}
        control={control}
        name={name}
        render={({
          field: { onChange, onBlur, value },
          fieldState: { isDirty },
          formState: { defaultValues },
        }) =>
          render({
            value,
            onChange,
            onBlur,
            defaultValue: getFieldValue(name, defaultValues),
            isDirty,
          })
        }
      />
    );
  }
  return render({ value, onChange, onBlur });
}
