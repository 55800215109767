import { Option } from '@/types';

export const getFieldValue = (name: string, field?: { [key: string]: any }) => {
  if (!field) return '';

  if (!name.includes('.')) {
    return field[name];
  }

  const [firstKey, ...keys] = name.split('.');

  return keys.reduce((acc, key) => acc?.[key], field?.[firstKey]);
};

export const getSelectOptions = <T extends Record<string, string | number>>(
  enumObj: T
): Option[] => {
  const options = Object.keys(enumObj).map((key) => ({
    text: enumObj[key].toString(),
    value: key,
  }));

  return options;
};
