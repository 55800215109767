export enum DocumentType {
  PASSPORT = 'Passport',
  ID_CARD = 'ID card',
  DRIVER_LICENSE = 'Driver license',
}

export enum Education {
  NO_FORMAL_EDUCATION = 'No formal education',
  ELEMENTARY_SCHOOL_COMPLETED = 'Elementary school completed',
  HIGH_SCHOOL_GRADUATE = 'High school graduate',
  BACHELORS_DEGREE = "Bachelor's degree",
  MASTERS_DEGREE = "Master's degree",
  DOCTORATE_DEGREE = 'Doctorate degree',
}

export enum EmploymentLength {
  LESS_THAN_SIX_MONTHS = '< 6 months',
  SIX_TO_TWELVE_MONTHS = '6 - 12 months',
  ONE_TO_TWO_YEARS = '1 - 2 years',
  TWO_TO_FOUR_YEARS = '2 - 4 years',
  MORE_THAN_FOUR_YEARS = '> 4 years',
}

export enum Industry {
  AGRICULTURE = 'Agriculture; Plantations; Other rural sectors',
  BASIC_METAL_PRODUCTION = 'Basic metal production',
  CHEMICAL_INDUSTRIES = 'Chemical industries',
  COMMERCE = 'Commerce',
  CONSTRUCTION = 'Construction',
  EDUCATION = 'Education',
  FINANCIAL_SERVICES = 'Financial services',
  FOOD_DRINK_TOBACCO = 'Food; Drink; Tobacco',
  FORESTRY_WOOD_PULP_AND_PAPER = 'Forestry; Wood; Pulp and paper',
  HEALTH_SERVICES = 'Health services',
  HOTELS_TOURISM_CATERING = 'Hotels; Tourism; Catering',
  INFORMATION_TECHNOLOGY = 'Information technology',
  MINING = 'Mining',
  MECHANICAL_ELECTRICAL = 'Mechanical and electrical engineering',
  MEDIA_CULTURE_GRAPHICAL = 'Media; Culture; Graphical',
  POSTAL_TELECOMMUNICATIONS = 'Postal and telecommunications services',
  PUBLIC_SERVICE = 'Public service',
  REAL_ESTATE_RENTAL_LEASING = 'Real estate; Rental; Leasing',
  REPAIR_MAINTENANCE = 'Repair and maintenance',
  SHIPPING_PORTS_FISHERIES_INLAND = 'Shipping; Ports; Fisheries; Inland waterways',
  TEXTILES_CLOTHING_LEATHER_FOOTWEAR = 'Textiles; Clothing; Leather; Footwear',
  TRANSPORT = 'Transport',
  UTILITIES = 'Utilities',
}

export enum MaritalStatus {
  MARRIED = 'Married',
  WIDOWED = 'Widowed',
  SEPARATED = 'Separated',
  DIVORCED = 'Divorced',
  SINGLE = 'Single',
}

export enum Position {
  TRAINEE = 'Trainee',
  REGULAR_EMPLOYEE = 'Regular employee',
  MIDDLE_LEVEL_MANAGER = 'Middle level manager',
  SENIOR_LEVEL_MANAGER = 'Senior level manager',
  OWNER = 'Owner',
  RETIRED = 'Retired',
  NON_EMPLOYED = 'Non-employed',
}

export enum PaymentFrequency {
  WEEKLY = 'Weekly',
  FORTNIGHTLY = 'Fortnightly',
  MONTHLY = 'Monthly',
  QUARTERLY = 'Quarterly',
}
