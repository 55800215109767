import {
  createTheme,
  type SimplePaletteColorOptions,
  type ThemeOptions,
} from '@mui/material';

import {
  blue,
  common,
  green,
  grey,
  orange,
  pink,
  purple,
  red,
  violet,
  yellow,
} from './constants/colors';
import type { Color } from './types';

declare module '@mui/material' {
  interface Color {
    1000: string;
  }
}

declare module '@mui/material/styles' {
  interface Palette {
    primary100: SimplePaletteColorOptions;
    primary400: SimplePaletteColorOptions;
    primary500: SimplePaletteColorOptions;
    primary700: SimplePaletteColorOptions;
    secondaryPink: Color;
    secondaryPurple: Color;
    secondaryViolet: Color;
    yellow: Color;
    green: Color;
    red: Color;
    orange: Color;
  }

  interface PaletteOptions {
    primary100: SimplePaletteColorOptions;
    primary400: SimplePaletteColorOptions;
    primary500: SimplePaletteColorOptions;
    primary700: SimplePaletteColorOptions;
    secondaryPink: Color;
    secondaryPurple: Color;
    secondaryViolet: Color;
    yellow: Color;
    green: Color;
    red: Color;
    orange: Color;
  }
}

const {
  palette: { augmentColor },
} = createTheme();

export const paletteConfigs: ThemeOptions['palette'] = {
  mode: 'light',
  primary: augmentColor({ color: blue, darkShade: 1000, lightShade: 100 }),
  primary100: augmentColor({ color: blue, mainShade: 100 }),
  primary400: augmentColor({ color: blue, mainShade: 400 }),
  primary500: augmentColor({ color: blue, mainShade: 500 }),
  primary700: augmentColor({ color: blue, mainShade: 700 }),
  secondaryPink: pink,
  secondaryPurple: purple,
  secondaryViolet: violet,
  success: augmentColor({ color: green }),
  error: augmentColor({ color: red }),
  warning: augmentColor({ color: yellow }),
  info: augmentColor({ color: blue }),
  yellow,
  green,
  red,
  orange,
  grey,
  background: {
    default: grey[100],
    paper: common.white,
  },
  divider: grey[500],
  text: {
    primary: grey[1000],
    secondary: grey[700],
  },
};
