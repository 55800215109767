import { Box, FormControl, FormLabel, Typography } from '@mui/material';
import { useState } from 'react';
import { Controller, FieldValues } from 'react-hook-form';

import { Button } from '@/components/Button';

import { UploadFileProps } from './types';
import { FileInput, StyledList, StyledListItem } from './UploadFile.styled';

export function UploadFile<FormPayload extends FieldValues>({
  label,
  control,
  name,
  rules,
  error,
  ...props
}: UploadFileProps<FormPayload>) {
  const [files, setFiles] = useState<File[] | null>(null);

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { value: _value, onChange, ...field } }) => {
        const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
          if (!event.target.files) return;

          const filesArray = [...event.target.files];

          setFiles(filesArray);
          onChange(filesArray);
        };

        return (
          <FormControl error={error} fullWidth>
            {label && <FormLabel>{label}</FormLabel>}
            <Box display="flex" alignItems="center" mt={0.5}>
              <Box position="relative" width={115}>
                <Button variant="secondary">Chose files</Button>
                <FileInput
                  type="file"
                  multiple
                  onChange={handleChange}
                  {...field}
                />
              </Box>
              {files ? (
                <StyledList>
                  {files.map((file) => (
                    <StyledListItem key={`${file.name}-${file.lastModified}`}>
                      <Typography
                        variant="body2"
                        textOverflow="ellipsis"
                        width="100%"
                        noWrap
                      >
                        {file.name}
                      </Typography>
                    </StyledListItem>
                  ))}
                </StyledList>
              ) : (
                <Typography variant="body2">No files chosen</Typography>
              )}
            </Box>
          </FormControl>
        );
      }}
      {...props}
    />
  );
}
