import type { ThemeOptions } from '@mui/material';
import type { CSSProperties } from 'react';

import { fontSizes, fontWeight } from './constants/fonts';

declare module '@mui/material/styles/createTypography' {
  interface TypographyOptions {
    fontWeightSemiBold?: number;
  }

  interface Typography {
    fontWeightSemiBold: number;
  }
}

declare module '@mui/material/styles' {
  interface TypographyVariants {
    body1Bold: CSSProperties;
    body1Medium: CSSProperties;
    body2Bold: CSSProperties;
    body2Medium: CSSProperties;
    captionBold: CSSProperties;
    caption2: CSSProperties;
    caption2Bold: CSSProperties;
  }

  interface TypographyVariantsOptions {
    body1Bold: CSSProperties;
    body1Medium: CSSProperties;
    body2Bold: CSSProperties;
    body2Medium: CSSProperties;
    captionBold: CSSProperties;
    caption2: CSSProperties;
    caption2Bold: CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body1Bold: true;
    body1Medium: true;
    body2Bold: true;
    body2Medium: true;
    captionBold: true;
    caption2: true;
    caption2Bold: true;
  }
}

export const typographyConfigs: ThemeOptions['typography'] = {
  fontFamily: 'Poppins',
  fontWeightRegular: fontWeight.regular,
  fontWeightMedium: fontWeight.medium,
  fontWeightSemiBold: fontWeight.semiBold,
  fontWeightBold: fontWeight.bold,
  allVariants: {
    lineHeight: 1.375,
  },
  h1: {
    fontSize: fontSizes.xxxxl,
    fontWeight: fontWeight.bold,
  },
  h2: {
    fontSize: fontSizes.xxxl,
    fontWeight: fontWeight.bold,
  },
  h3: {
    fontSize: fontSizes.xxl,
    fontWeight: fontWeight.bold,
  },
  h4: {
    fontSize: fontSizes.xl,
    fontWeight: fontWeight.bold,
  },
  h5: {
    fontSize: fontSizes.lg,
    fontWeight: fontWeight.semiBold,
  },
  body1: {
    fontSize: fontSizes.md,
  },
  body1Medium: {
    fontSize: fontSizes.md,
    fontWeight: fontWeight.medium,
  },
  body1Bold: {
    fontSize: fontSizes.md,
    fontWeight: fontWeight.semiBold,
  },
  body2: {
    fontSize: fontSizes.sm,
  },
  body2Medium: {
    fontSize: fontSizes.sm,
    fontWeight: fontWeight.medium,
  },
  body2Bold: {
    fontSize: fontSizes.sm,
    fontWeight: fontWeight.semiBold,
  },
  caption: {
    fontSize: fontSizes.xs,
  },
  captionBold: {
    fontSize: fontSizes.xs,
    fontWeight: fontWeight.semiBold,
  },
  caption2: {
    fontSize: fontSizes.xxs,
  },
  caption2Bold: {
    fontSize: fontSizes.xxs,
    fontWeight: fontWeight.semiBold,
  },
};
