import { Alert, Slide, Snackbar as MuiSnackbar } from '@mui/material';

import { SnackbarProps } from './types';

export function Snackbar({
  open,
  variant = 'success',
  message,
  autoHideDuration = 3000,
  onClose,
}: SnackbarProps) {
  return (
    <MuiSnackbar
      open={open}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      autoHideDuration={autoHideDuration}
      TransitionComponent={Slide}
      onClose={onClose}
    >
      <Alert
        severity={variant}
        variant="filled"
        sx={{ width: '100%' }}
        onClose={onClose}
      >
        {message}
      </Alert>
    </MuiSnackbar>
  );
}
