import { Option } from '@/types';

export enum Country {
  USA = 'USA',
  TT = 'TT',
}

export const countryOptions: Option[] = [
  {
    value: 'USA',
    text: 'USA',
    icon: 'usa',
  },
  {
    value: 'TT',
    text: 'Trinidad and Tobago',
    icon: 'tt',
  },
];
