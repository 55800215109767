import { type Shadows } from '@mui/material';

export const shadowsConfigs = [
  'none',
  '0px 2px 8px 0px #A3A6BD14',
  '0px 4px 16px 0px #A3A6BD14',
  '0px 4px 48px 0px #A3A6BD1F',
  '0px 2px 8px 0px #59667D14',
  ...Array(20).fill('none'),
] as Shadows;
