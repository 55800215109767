import { type Components, createTheme } from '@mui/material';

import { layoutConfigs } from './layout';
import { paletteConfigs } from './palette';
import { typographyConfigs } from './typography';

const theme = createTheme({
  palette: paletteConfigs,
  typography: typographyConfigs,
  ...layoutConfigs,
});

export const buttonConfigs: Components['MuiButton'] = {
  defaultProps: {
    disableElevation: true,
    disableFocusRipple: true,
    disableRipple: true,
    variant: 'contained',
    color: 'primary',
  },
  variants: [
    {
      props: { variant: 'outlined' },
      style: {
        '&:hover': {
          backgroundColor: theme.palette.common.white,
          color: theme.palette.grey[700],
          border: `1px solid ${theme.palette.grey[700]}`,
        },
      },
    },
  ],
  styleOverrides: {
    root: {
      paddingLeft: 18,
      paddingRight: 18,
      fontFamily: 'Poppins',
      ...theme.typography.body2Bold,
      lineHeight: 1.3,
      textTransform: 'initial',
      height: 44,

      '.MuiButton-startIcon': {
        marginRight: theme.spacing(0.75),
        '.MuiButtonBase-root': {
          padding: 0,
        },
      },
      '&.MuiButton-sizeSmall': {
        height: 40,
      },
      '.MuiButton-endIcon': {
        marginLeft: theme.spacing(0.75),
        '.MuiButtonBase-root': {
          padding: 0,
        },
      },
    },
    contained: {
      ':active, :hover': {
        backgroundColor: theme.palette.primary400.main,
      },
      '.MuiButton-startIcon, .MuiButton-endIcon': {
        '.MuiSvgIcon-root': {
          fill: theme.palette.primary.contrastText,
        },
      },
    },
    text: {
      color: theme.palette.text.primary,
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
      paddingBottom: theme.spacing(0.75),
      paddingTop: theme.spacing(0.75),
    },
    outlined: {
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
      borderColor: theme.palette.text.primary,
      ':hover': {
        opacity: 0.8,
      },
      ':active': {
        opacity: 0.8,
      },
    },
  },
};
