import type { Components } from '@mui/material';
import { createTheme } from '@mui/material';

import { paletteConfigs } from './palette';
import { typographyConfigs } from './typography';

const theme = createTheme({
  palette: paletteConfigs,
  typography: typographyConfigs,
});

export const formControlLabelConfigs: Components['MuiFormControlLabel'] = {
  styleOverrides: {
    root: {
      color: theme.palette.grey[1000],
      '.MuiTypography-root': {
        ...theme.typography.body2Medium,
      },
    },
  },
};
