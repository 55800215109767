/// <reference types="vite-plugin-svgr/client" />

import { default as ArrowDown } from '@/assets/icons/ArrowDown.svg?react';
import { default as ArrowSquareOut } from '@/assets/icons/ArrowSquareOut.svg?react';
import { default as ArrowUp } from '@/assets/icons/ArrowUp.svg?react';
import { default as Bell } from '@/assets/icons/Bell.svg?react';
import { default as Calendar } from '@/assets/icons/Calendar.svg?react';
import { default as Checkbox } from '@/assets/icons/Checkbox.svg?react';
import { default as CheckboxChecked } from '@/assets/icons/CheckboxChecked.svg?react';
import { default as CheckCircle } from '@/assets/icons/CheckCircle.svg?react';
import { default as Checkmark } from '@/assets/icons/Checkmark.svg?react';
import { default as Download } from '@/assets/icons/Download.svg?react';
import { default as Edit } from '@/assets/icons/Edit.svg?react';
import { default as Email } from '@/assets/icons/Email.svg?react';
import { default as Eye } from '@/assets/icons/Eye.svg?react';
import { default as EyeClosed } from '@/assets/icons/EyeClosed.svg?react';
import { default as File } from '@/assets/icons/File.svg?react';
import { default as TT } from '@/assets/icons/flags/TT.svg?react';
import { default as USA } from '@/assets/icons/flags/USA.svg?react';
import { default as IdentificationCard } from '@/assets/icons/IdentificationCard.svg?react';
import { default as Info } from '@/assets/icons/Info.svg?react';
import { default as Lock } from '@/assets/icons/Lock.svg?react';
import { default as LogoLong } from '@/assets/icons/LogoLong.svg?react';
import { default as Note } from '@/assets/icons/Note.svg?react';
import { default as Phone } from '@/assets/icons/Phone.svg?react';
import { default as Plus } from '@/assets/icons/Plus.svg?react';
import { default as Printer } from '@/assets/icons/Printer.svg?react';
import { default as Search } from '@/assets/icons/Search.svg?react';
import { default as SendFilled } from '@/assets/icons/SendFilled.svg?react';
import { default as Upload } from '@/assets/icons/Upload.svg?react';
import type { IconName } from '@/types';

export const icons: Record<IconName, React.FC> = {
  'arrow-square-out': ArrowSquareOut,
  'arrow-down': ArrowDown,
  'arrow-up': ArrowUp,
  bell: Bell,
  calendar: Calendar,
  'check-circle': CheckCircle,
  checkbox: Checkbox,
  'checkbox-checked': CheckboxChecked,
  checkmark: Checkmark,
  download: Download,
  edit: Edit,
  email: Email,
  eye: Eye,
  'eye-closed': EyeClosed,
  file: File,
  'identification-card': IdentificationCard,
  info: Info,
  lock: Lock,
  'logo-long': LogoLong,
  note: Note,
  phone: Phone,
  plus: Plus,
  printer: Printer,
  search: Search,
  'send-filled': SendFilled,
  upload: Upload,
  usa: USA,
  tt: TT,
};
