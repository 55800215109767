import type { Components } from '@mui/material';

export const helperTextConfigs: Components['MuiFormHelperText'] = {
  styleOverrides: {
    root: {
      '.MuiFormControl-root &.Mui-error': {
        fontWeight: 400,
      },
    },
  },
};
