import {
  Box,
  FormHelperText,
  ListItemIcon,
  ListItemText,
  Select as MUISelect,
  Typography,
} from '@mui/material';
import type { ReactNode } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import type { FieldValues } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import { useBoolean } from 'usehooks-ts';

import type { Option } from '@/types';
import { getFieldValue } from '@/utils';

import { Icon } from '../../Icon';
import { FieldArrowButton } from '../Form.styled';
import {
  DropdownOption,
  Label,
  SelectedOption,
  SelectGroupName,
  StyledFormControl,
} from './SelectField.styled';
import type { SelectFieldProps } from './types';

export function SelectField<FormPayload extends FieldValues>({
  options,
  control,
  name,
  rules,
  disabled,
  error,
  label,
  placeholder,
  fullWidth,
  ...props
}: SelectFieldProps<FormPayload>) {
  const { value: isOpen, setFalse: hide, setTrue: show } = useBoolean();
  const [search, setSearch] = useState('');

  const optionByValue: Record<string, Option> = useMemo(() => {
    return options.reduce(
      (acc, option) => ({
        ...acc,
        ['' + option.value]: option,
      }),
      {}
    );
  }, [options]);

  useEffect(() => {
    if (isOpen) setSearch('');
  }, [isOpen]);

  const groups = useMemo(() => {
    if (search) {
      return [
        {
          name: '',
          order: 0,
          items: options.filter(
            (item) =>
              item.text?.toLowerCase()?.includes(search.toLowerCase()) ||
              String(item.value).toLowerCase().includes(search.toLowerCase())
          ),
        },
      ];
    }

    const groupByName: Record<
      string,
      {
        name: string;
        order: number;
        items: Option[];
      }
    > = {};
    options.forEach((option: Option) => {
      const groupName = option.group || 'undefined';
      if (!groupByName[groupName]) {
        groupByName[groupName] = {
          name: groupName,
          order: option.groupOrder || 0,
          items: [],
        };
      }
      groupByName[groupName].items.push(option);
    });

    const result = Object.values(groupByName);
    result.sort((a, b) => b.order - a.order);

    return result;
  }, [options, search]);

  const renderOptions = useCallback(
    (currentValue: unknown) => {
      const components: ReactNode[] = [];

      groups.forEach((group) => {
        if (groups.length > 1) {
          components.push(
            <SelectGroupName key={group.name}>
              {group.name === 'undefined' ? 'Other' : group.name}
            </SelectGroupName>
          );
        } else {
          components.push(<Box key="top-padding" pt={2} />);
        }
        group.items.forEach((option) => {
          components.push(
            <DropdownOption
              key={option.value}
              value={option.value}
              disabled={!option.value}
            >
              {option.icon && (
                <ListItemIcon>
                  <Icon name={option.icon} size={20} />
                </ListItemIcon>
              )}
              <ListItemText>
                <Typography
                  component="span"
                  variant={
                    currentValue === option.value ? 'body2Bold' : 'body2'
                  }
                >
                  {option.text || option.value}
                </Typography>
              </ListItemText>
            </DropdownOption>
          );
        });
      });

      return components;
    },
    [groups]
  );

  const renderSelectValue = useCallback(
    (_value: unknown, defaultValue: unknown) => {
      const currentValue = _value || defaultValue;
      const currentOption = optionByValue[String(currentValue)] || {};

      return (
        <SelectedOption>
          {currentOption.icon && (
            <ListItemIcon>
              <Icon name={currentOption.icon} size={20} />
            </ListItemIcon>
          )}
          <ListItemText>
            <Typography
              component="span"
              variant="body2"
              color={!currentValue ? 'textSecondary' : 'textPrimary'}
            >
              {currentOption.text ||
                currentOption.value ||
                (!!currentValue && String(currentValue)) ||
                placeholder}
            </Typography>
          </ListItemText>
        </SelectedOption>
      );
    },
    [optionByValue, placeholder]
  );

  return (
    <Controller
      rules={rules}
      control={control}
      name={name}
      render={({
        field: { onChange, onBlur, value },
        formState: { defaultValues },
      }) => {
        return (
          <StyledFormControl
            fullWidth={fullWidth}
            error={!!error}
            disabled={disabled}
          >
            {label && <Label error={!!error}>{label}</Label>}
            <MUISelect
              label={label}
              placeholder={placeholder}
              fullWidth
              displayEmpty
              onChange={onChange}
              defaultValue={getFieldValue(name, defaultValues) || ''}
              onBlur={onBlur}
              value={value || ''}
              open={isOpen}
              onOpen={show}
              onClose={hide}
              IconComponent={() => (
                <FieldArrowButton
                  onClick={isOpen ? hide : show}
                  disabled={disabled}
                >
                  <Icon
                    name={isOpen ? 'arrow-up' : 'arrow-down'}
                    size={20}
                    color={(theme) => theme.palette.grey[700]}
                  />
                </FieldArrowButton>
              )}
              renderValue={(selected) =>
                renderSelectValue(selected, getFieldValue(name, defaultValues))
              }
              {...props}
            >
              {renderOptions(value)}
            </MUISelect>
            <FormHelperText>{error}</FormHelperText>
          </StyledFormControl>
        );
      }}
    />
  );
}
